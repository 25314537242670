<template xmlns="http://www.w3.org/1999/html">
  <div class="main-content">
    <div class="title">清除缓存</div>
    <div style="margin: 16px;">
      <van-button type="info" round block @click="onClearCache">点击清除</van-button>
    </div>
  </div>
</template>

<script>

import {clearCacheData} from "../../utils/base";
import {Toast} from "vant";

export default {
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {
    onClearCache(){

      clearCacheData();

      if (!localStorage.removeItem("token")) {
        Toast.success('清除缓存成功，请尝试重新访问其它页面');
      } else {
        Toast.success('清除失败，请重试');
      }
    },
  },
};
</script>

<style>
.main-content .title{
  font-size: large;
  font-weight: bold;
  margin: 10px auto;
  text-align: center;
}
.main-content .desc {
  color: #7F7F7F;
  line-height: 2em;
}
.main-content .desc .desc-title {
  color: red;
  font-weight: bold;
}
</style>